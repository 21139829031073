import React from "react";
import Text from "@/bit/components/components.text";
import CustomLink from "@/bit/components/components.custom-link";
import Counter from "@/bit/components/components.countdown-date-timer";

function NeonBanner(props) {
  const component = props["Component"];
  const neonText = props["Text-1"];
  const glowText = props["Text-2"];
  const titleDesktop = props["Text-3"];
  const titleMobile = props["Text-4"];
  const subtitle = props["Text-5"];
  const counter = props["Counter-1"];
  const link = props["CustomLink-1"];
  if (!component || component == "") {
    return null;
  }
  return (
    <>
      <div className="neonbanner container">
        <div className="content">
          <div className="left">
            <Text className="neon-text" {...neonText} />
            <Text className="glow-text" {...glowText} />
          </div>
          <div className="separator"></div>
          <div className="right">
            <div className="info">
              {titleDesktop && (
                <div className="title-desktop">
                  <Text {...titleDesktop} />
                </div>
              )}
              {titleMobile && (
                <div className="title-mobile">
                  <Text {...titleMobile} />
                </div>
              )}
              {subtitle && (
                <div className="subtitle">
                  <Text {...subtitle} />
                </div>
              )}
              {counter && (
                <div className="counter">
                  <Counter {...counter} />
                </div>
              )}
            </div>
          </div>
          {link && (link.href || link.onClick) && <CustomLink {...link} />}
          <div className={`${props?.skeletonClassName}`}></div>
        </div>
      </div>
      <style jsx>{`.neonbanner {
  position: relative;
  margin-bottom: 0;
  margin-top: 0.5rem;
  overflow: hidden;
  .content {
    display: flex;
    flex-direction: column;
    background: linear-gradient(135deg, #066fcb, #0b2739 80%);
    .left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0.5rem;
      gap: 0.5rem;
      :global(.neon-text) {
        font-size: 66px;
        text-shadow:
          0 0 5px #ffffff,
          0 0 10px #ffffff;

        line-height: 0.85;
      }
      :global(.glow-text) {
        font-size: 1.5rem;
        text-shadow:
          0 0 20px #066fcb,
          0 0 40px #066fcb,
          0 0 60px #066fcb,
          0 0 80px #066fcb;
      }
    }
    .separator {
      display: none;
    }
    .right {
      flex: 1;
      .info {
        padding: 0.5rem;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        gap: 0.4rem;
        .title-desktop {
          display: none;
        }
        .subtitle {
          display: none;
        }
      }
    }
  }


  @media screen and (min-width: 768px) {
    .content {
      flex-direction: row;
      background: linear-gradient(90deg, #066fcb, #0b2739 80%);
      .left {
        :global(.neon-text) {
          font-size: 66px;
          line-height: 0.9;
          animation: flicker 2s infinite;
        }

        :global(.glow-text) {
          font-size: 24px;
        }
        @keyframes flicker {
          0% {
            opacity: 1;
          }
          5% {
            opacity: 0.3;
          }
          10% {
            opacity: 0.8;
          }
          15% {
            opacity: 0.1;
          }
          25% {
            opacity: 1;
          }
          30% {
            opacity: 0.6;
          }
          40% {
            opacity: 0.9;
          }
          50% {
            opacity: 0.2;
          }
          60% {
            opacity: 1;
          }
          70% {
            opacity: 0.4;
          }
          80% {
            opacity: 1;
          }
          90% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      }
      .separator {
        display: block;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.6);
        margin: 1rem 0;
      }
      .right {
        .info {
          padding: 1rem 2rem;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          gap: 0px;
          .title-desktop {
            display: block;
          }
          .title-mobile {
            display: none;
          }
          .subtitle {
            display: block;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .content {
      .right {
        .info {
          .subtitle {
            display: block;
          }
        }
      }
      .left {
      }
    }
  }
}`}</style>
    </>
  );
}

export default NeonBanner;
